<script lang="ts">
    import { onMount } from 'svelte';
    import { inertia, router } from '@inertiajs/svelte';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import { DateTime } from 'luxon';
    import { Icon, Eye, Heart, UserGroup, Sparkles, Banknotes } from 'svelte-hero-icons';
    import axios from 'axios';
    import ApexCharts from 'apexcharts';
    import Flatpickr from 'svelte-flatpickr';
    import 'flatpickr/dist/flatpickr.css';

    export let currentUSerId: string;

    export let cookies: string;

    let type: string = 'view';

    let selectedTypeLabel: string = 'Views';

    let selectedIcon: any = Eye;

    let selectedTimeframe: string = 'Last 7 Days';

    let timeframeValue: string = 'sevenDays';

    let scale: string = 'd';

    let source: string = 'post';

    let amount: number = 7;

    let offset: number = 0;

    let currentRoute = '';

    let chart: any;

    let chartContainer: HTMLElement;

    const currentDate = new Date();

    let currentRange: any = { start: null, end: null };

    let formattedRange: any = { start: null, end: null };

    let isCustomRange = false;

    let customRange: any = [];

    let isChartLoading = false;

    let isValueLoading = false;

    let bestposts:any = [];

    const currentYear = currentDate.getFullYear();
    const currentMonthNumber = currentDate.getMonth();
    const prevYear = currentYear - 1;
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1).toLocaleString('default', {
        month: 'long',
    });
    const prevPMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1).toLocaleString('default', {
        month: 'long',
    });

    const getMonthRange = (year: any, month: any) => {
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        return { start: firstDay, end: lastDay };
    };

    const currentMonthRange = getMonthRange(currentYear, currentMonthNumber);
    const prevMonthRange = getMonthRange(currentYear, currentMonthNumber - 1);
    const monthbeforePrevRange = getMonthRange(currentYear, currentMonthNumber - 2);

    const formatDate = (date: any) => DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');

    const predefinedTimes = () => {
        let startDate: string, endDate: string;

        switch (timeframeValue) {
            case 'currentYear':
                startDate = `${currentYear}-01-01`;
                endDate = `${currentYear}-12-31`;
                break;
            case 'prevYear':
                startDate = `${prevYear}-01-01`;
                endDate = `${prevYear}-12-31`;
                break;
            case 'currentMonth':
                startDate = formatDate(currentMonthRange.start);
                endDate = formatDate(currentMonthRange.end);
                break;
            case 'prevMonth':
                startDate = formatDate(prevMonthRange.start);
                endDate = formatDate(prevMonthRange.end);
                break;
            case 'prevPMonth':
                startDate = formatDate(monthbeforePrevRange.start);
                endDate = formatDate(monthbeforePrevRange.end);
                break;
            default:
                startDate = `${currentYear}-01-01`;
                endDate = `${currentYear}-12-31`;
                break;
        }

        updateRangeAndChart(startDate, endDate);
    };

    router.reload({ only: ['currentUserId'] });

    let chartOptions = {
        chart: {
            height: 400,
            type: 'area',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        series: [
            {
                name: selectedTypeLabel,
                data: [],
            },
        ],
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
            width: 2,
        },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 1,
                opacityFrom: 0.2,
                opacityTo: 0.8,
                stops: [0, 90, 100],
            },
        },
        xaxis: {
            type: 'category',
            tickPlacement: 'on',
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            crosshairs: {
                stroke: {
                    dashArray: 0,
                },
                dropShadow: {
                    show: false,
                },
            },
            tooltip: {
                enabled: false,
            },
            labels: {
                style: {
                    colors: '#9ca3af',
                    fontSize: '13px',
                    fontFamily: 'Inter, ui-sans-serif',
                    fontWeight: 400,
                },
                formatter: (title: any) => {
                    if (typeof title !== 'string' || !title) {
                        return '';
                    }
                    const parts = title.split(' ');
                    if (parts.length >= 2) {
                        return `${parts[0]} ${parts[1].slice(0, 3)}`;
                    }
                    return title;
                },
            },
        },
        yaxis: {
            labels: {
                align: 'left',
                minWidth: 0,
                maxWidth: 140,
                style: {
                    colors: '#9ca3af',
                    fontSize: '12px',
                    fontFamily: 'Inter, ui-sans-serif',
                    fontWeight: 400,
                },
                formatter: (value: any) => (value >= 1000 ? `${value / 1000}k` : value),
            },
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy',
            },
        },

        responsive: [
            {
                breakpoint: 568,
                options: {
                    chart: {
                        height: 300,
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '11px',
                            },
                            offsetX: -2,
                            formatter: (title: any) => title.slice(0, 3),
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                fontSize: '11px',
                            },
                        },
                    },
                },
            },
        ],
    };

    const typedropdown = [
        { icon: Eye, item: 'Views', value: 'view' },
        { icon: Heart, item: 'Likes', value: 'like' },
        { icon: UserGroup, item: 'Follow', value: 'follow' },
    ];

    const timeframe = [
        { value: 'sevenDays', label: 'Last 7 Days' },
        { value: 'oneMonth', label: 'Last Month' },
        { value: 'threeMonths', label: 'Last 3 Month' },
        { value: 'oneYear', label: 'Last Year' },
        { value: 'currentYear', label: currentYear },
        { value: 'prevYear', label: prevYear },
        { value: 'currentMonth', label: currentMonth },
        { value: 'prevMonth', label: prevMonth },
        { value: 'prevPMonth', label: prevPMonth },
        { value: 'custom', label: 'Custom' },
    ];

    let periodValue = '';
    let periodPercent = '';
    let percentColor = '';
    let percentBackgroundColor = '';

    const handleItemChange = () => {
        if (timeframeValue === 'custom') {
            if (currentRange.start && currentRange.end) {
                updateChartCustom();
            }
        } else {
            if (
                timeframeValue === 'currentYear' ||
                timeframeValue === 'prevYear' ||
                timeframeValue === 'currentMonth' ||
                timeframeValue === 'prevMonth' ||
                timeframeValue === 'prevPMonth'
            ) {
                predefinedTimes();
            } else {
                updateChart();
            }
        }
    };

    const updateType = (newType: string, typeLabel: string, typeIcon: any) => {
        type = newType;
        selectedTypeLabel = typeLabel;
        selectedIcon = typeIcon;
        source = type === 'follow' ? 'profile' : 'post';

        handleItemChange();
    };

    const updateTimeframe = (selectedValue: any, selectedLabel: any) => {
        selectedTimeframe = selectedLabel;
        timeframeValue = selectedValue;

        switch (selectedValue) {
            case 'sevenDays':
                scale = 'd';
                amount = 7;
                break;
            case 'oneMonth':
                scale = 'd';
                amount = 30;
                break;
            case 'threeMonths':
                scale = 'd';
                amount = 90;
                break;
            case 'oneYear':
                scale = 'm';
                amount = 12;
                break;
            default:
                scale = 'd';
                amount = 7;
                break;
        }

        handleItemChange();
    };

    const updateChart = async () => {
        showLoaders();

        bestPosts(type, scale, amount, offset);

        isCustomRange = false;
        try {
            const response = await axios.get(
                `/analytics/v1/${source}/${currentUSerId}/${type}?scale=${scale}&amount=${amount}&offset=${offset}`
            );

            if (response && response.status === 200) {
                hideLoaders();

                const { data, labels, growth, last } = response.data;
                statsUpdate(last, growth);

                if (Array.isArray(labels) && labels.length > 0) {
                    chart.updateSeries([
                        {
                            name: selectedTypeLabel,
                            data: data.map((value: any, index: any) => ({
                                x: labels[index][0] || '',
                                y: value,
                            })),
                        },
                    ]);

                    chart.updateOptions({
                        xaxis: {
                            categories: labels.map((label: any) => label[0] || ''),
                        },
                    });
                }
            }
        } catch (error) {
            hideLoaders();
            console.error('Error fetching data:', error);
        } finally {
            hideLoaders();
        }
    };

    const updateChartCustom = async () => {
        showLoaders();
        isCustomRange = true;

        if (currentRange.start && currentRange.end) {
            let apiUrl = `/analytics/v1/${source}/${currentUSerId}/${type}?start_date=${encodeURIComponent(
                currentRange.start
            )}&end_date=${encodeURIComponent(currentRange.end)}`;

            try {
                const response = await axios.get(apiUrl);

                hideLoaders();

                if (response && response.status === 200) {
                    const { data, labels } = response.data;

                    chart.updateSeries([
                        {
                            name: selectedTypeLabel,
                            data: data.map((value: any, index: any) => ({
                                x: labels[index][0],
                                y: value,
                            })),
                        },
                    ]);

                    chart.updateOptions({
                        xaxis: {
                            categories: labels.map((label: any) => label[0]),
                        },
                    });
                }
            } catch (error) {
                hideLoaders();
                console.error('Error fetching custom range data:', error);
            } finally {
                hideLoaders();
            }
        }
    };

    const updateRangeAndChart = (startDate: string, endDate: string) => {
        formattedRange.start = DateTime.fromISO(startDate).toFormat('yyyy-MM-dd');
        formattedRange.end = DateTime.fromISO(endDate).toFormat('yyyy-MM-dd');

        currentRange.start = startDate + ' 00:00:00';
        currentRange.end = endDate + ' 23:59:59';

        updateChartCustom();
    };

    const statsUpdate = (stats: string, percent: number) => {
        let percentRound = Math.ceil(percent);
        periodValue = stats;
        percentColor = percent > 0 ? '#00FFA3' : percent < 0 ? '#ff0000' : 'rgba(128, 128, 128, 1)';
        percentBackgroundColor =
            percent < 0 ? 'rgba(255, 20, 64, 0.12)' : percent > 0 ? '' : 'rgba(117, 117, 117, 0.12)';
        const sign = percent > 0 ? '+' : '';
        periodPercent = `${sign}${percentRound}%`;
    };

    const handleDate = () => {
        $: if (timeframeValue === 'custom') {
            if (customRange[0] && customRange[1]) {
                currentRange.start = DateTime.fromJSDate(customRange[0]).toFormat('yyyy-MM-dd') + ' 00:00:00';
                currentRange.end = DateTime.fromJSDate(customRange[1]).toFormat('yyyy-MM-dd') + ' 23:59:59';

                formattedRange.start = DateTime.fromJSDate(customRange[0]).toFormat('yyyy-MM-dd');
                formattedRange.end = DateTime.fromJSDate(customRange[1]).toFormat('yyyy-MM-dd');

                updateChartCustom();
            }
        }
    };

    const showLoaders = () => {
        isChartLoading = true;
        isValueLoading = true;
    };

    const hideLoaders = () => {
        isChartLoading = false;
        isValueLoading = false;
    };

    const bestPosts = async (type: string, scale: string, amount: number, offset: number) => {
        let apiUrl = `/analytics/best/v1/${type}?scale=${scale}&amount=${amount}&offset=${offset}`;

        const response = await axios.get(apiUrl);

        if (response && response.status === 200) {
            bestposts = response.data.posts;
            console.log('Res', response.data);
        }
    };

    onMount(() => {
        currentRoute = window.location.pathname;
        chart = new ApexCharts(chartContainer, chartOptions);
        chart.render();
        handleItemChange();
    });
</script>

<div class="min-vh-100 pr-0 pt-4">
    <div class="px-4">
        <h5 class="text-truncate mb-0 font-bold dark:text-white {cookies}">Analytics Dashboard</h5>
    </div>

    <div class="inline-border-tabs my-3">
        <nav class="nav nav-pills nav-justified text-bold mt-3">
            <a
                class="nav-item nav-link flex items-center justify-center gap-x-1 {currentRoute ===
                `/analytics/engagement`
                    ? 'active'
                    : ''}"
                href="{`/analytics/engagement`}"
                use:inertia
            >
                <Icon src="{Sparkles}" size="{'22px'}" />
                Engagement
            </a>
            <a
                class="nav-item nav-link flex items-center justify-center gap-x-1 {currentRoute === `/analytics/revenue`
                    ? 'active'
                    : ''}"
                href="{`/analytics/revenue`}"
                use:inertia
            >
                <Icon src="{Banknotes}" size="{'22px'}" />
                Revenue
            </a>
        </nav>
    </div>

    <div class="px-3 py-1">
        <div class="toggle-analyt">
            <div class="flex gap-x-2">
                <DropdownMenu.Root>
                    <DropdownMenu.Trigger class="flex rounded-md bg-neutral-100 px-4 py-2 dark:bg-neutral-800">
                        <Icon src="{selectedIcon}" size="{'22px'}" solid />&nbsp;&nbsp;{selectedTypeLabel}
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                        <DropdownMenu.Group>
                            {#each typedropdown as type}
                                <DropdownMenu.Item
                                    on:click="{() => updateType(type.value, type.item, type.icon)}"
                                    class="flex"
                                >
                                    <Icon src="{type.icon}" size="{'22px'}" solid />&nbsp;&nbsp;{type.item}
                                </DropdownMenu.Item>
                            {/each}
                        </DropdownMenu.Group>
                    </DropdownMenu.Content>
                </DropdownMenu.Root>

                <DropdownMenu.Root>
                    <DropdownMenu.Trigger class="flex rounded-md bg-neutral-100 px-4 py-2 dark:bg-neutral-800"
                        >{selectedTimeframe}</DropdownMenu.Trigger
                    >
                    <DropdownMenu.Content>
                        <DropdownMenu.Group>
                            {#each timeframe as time}
                                <DropdownMenu.Item on:click="{() => updateTimeframe(time.value, time.label)}"
                                    >{time.label}</DropdownMenu.Item
                                >
                            {/each}
                        </DropdownMenu.Group>
                    </DropdownMenu.Content>
                </DropdownMenu.Root>

                {#if timeframeValue === 'custom'}
                    <Flatpickr
                        bind:value="{customRange}"
                        options="{{
                            mode: 'range',
                            dateFormat: 'Y-m-d',
                        }}"
                        on:change="{handleDate}"
                        class="form-control h-9 w-56 rounded-md text-center text-sm shadow-none focus:border-bmn-500"
                    />
                {/if}
            </div>

            <div class="stats mt-3">
                {#if isCustomRange}
                    <span class="period-stats text-lg text-black dark:!text-white">
                        {formattedRange.start} - {formattedRange.end}
                    </span>
                {:else}
                    <span class="period-stats text-lg text-black dark:!text-white">
                        {amount}{scale}
                    </span>
                {/if}
                <div class="d-flex stats-values relative items-center">
                    {#if isValueLoading}
                        <div class="absolute bottom-0 left-0 top-0 z-50 w-full rounded-lg">
                            <div class="h-full w-full animate-pulse">
                                <div class="h-full w-full rounded-lg bg-gray-200"></div>
                            </div>
                        </div>
                    {/if}
                    <span class="font-mona-expanded text-6xl font-black text-black dark:!text-white">
                        {periodValue}
                    </span>&nbsp;&nbsp;&nbsp;
                    <span
                        class="rounded-md px-2 py-1 text-lg font-bold"
                        style="color: {percentColor}; background-color: {percentBackgroundColor}"
                    >
                        {periodPercent}
                    </span>
                </div>
            </div>

            <div id="chart-container" style="position: relative; top: 4px">
                {#if isChartLoading}
                    <div class="absolute bottom-0 left-0 right-0 top-0 z-50 !h-full !w-full rounded-lg">
                        <div class="h-full w-full animate-pulse">
                            <div class="!h-full !w-full rounded-lg bg-gray-200"></div>
                        </div>
                    </div>
                {/if}
                <div bind:this="{chartContainer}" id="chart"></div>
            </div>

            <div class="best-performance-posts mt-3 px-3 py-2">
                <h5 class="font-bold text-black dark:!text-stone-200">Best Performing Posts</h5>

                <div class="posts-main">
                    {#each bestposts as post}
                        <div class="post-item w-100 mt-2 rounded-lg bg-slate-200 p-3 dark:bg-slate-500">
                            <div class="best-posts-stats flex justify-between">
                                <h6>
                                    <b>Count:</b> ${post.count}
                                </h6>
                                <h6>
                                    <b>Post Score:</b> ${post.post_score}
                                </h6>
                                <h6>
                                    <b>Price:</b> ${post.price}
                                </h6>
                            </div>
                            <span>${post.text}</span>
                        </div>
                    {/each}
                </div>
            </div>
        </div>
    </div>
</div>